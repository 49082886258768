.dashboard {
  @import 'stats';
  height: 100vh;
  display: flex;
  flex-direction: column;

  .card {
    @include media-breakpoint-down(md) {
      margin-bottom: $grid-gutter-width-sm;
    }
    @include media-breakpoint-between(lg, xxl) {
      margin-bottom: $grid-gutter-width;
    }
    @include media-breakpoint-up(xxl) {
      margin-bottom: $grid-gutter-width-lg;
    }
    .card-header {
      font-size: $h2-font-size;
      font-family: $font-semi-bold;
      border-bottom: 0;
    }

    .error {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      height: 80px;
    }

    &.overdue-title-card,
    &.expiring-titles-card,
    &.last-bordereau-card,
    &.customer-concentration-card {
      .card-body {
        padding-top: 0;
      }
    }

    &.overdue-title-card,
    &.expiring-titles-card {
      height: 305px;
      .card-body {
        height: 210px;
        overflow-y: auto;
        padding-bottom: 0;
        .table {
          margin-bottom: 0;
        }
      }
      .card-header {
        .total {
          margin-left: 10px;
          font-family: $font-bold;
          font-size: 28px;
          color: $danger;
        }
      }
    }

    &.last-bordereau-card,
    &.customer-concentration-card {
      height: 274px;
      .card-body {
        height: 200px;
        overflow: auto;
        padding-bottom: 0;
        .table {
          margin-bottom: 0;
        }
      }
    }

    &.last-bordereau-card {
      .badge-rounded {
        width: 17px;
        padding: 0 auto;
        height: 17px;
        border-radius: 50%;
        display: inline-block;
        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }

    .card-footer {
      background-color: white;
      border: 0;
      padding-top: 0;

      a {
        text-decoration: underline;
        font-size: 10px;
        font-family: $font-semi-bold;
      }
    }

    .table {
      thead {
        th {
          border-width: 1px;
          background-color: #f6f6f6;
          font-size: 12px;
          color: #4a4a4a;
        }
      }
      td,
      tbody + tbody {
        border: 0;
      }
    }

    &.liquidity {
      height: 314px;
    }
  }

  span.loading-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    height: 100%;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    .card {
      &.overdue-title-card {
        .card-body {
          .table {
            tbody {
              td {
                span {
                  @include ellipsis();
                  max-width: 120px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .card {
      margin-bottom: 16px;
      .card-header,
      .card-footer,
      .card-body {
        padding: $grid-gutter-width-sm $grid-gutter-width-sm;
      }
      &.overdue-title-card {
        .card-body {
          .table {
            tbody {
              td {
                span {
                  @include ellipsis();
                  max-width: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.margenPersonalizada {
  margin-left: -22px;
  margin-bottom: -15px;
  margin-top: -40px;
  margin-bottom: -16px;
}

.botaoX .close {
  color: steelblue;
}

.modalFimAno {
  height: 95% !important;
  overflow-y: hidden !important;
}

.modalAlertaBordero {
  height: 95% !important;
  overflow-y: hidden !important;
}

.imgBackGround {
  background-image: url('assets/img/inclusaoBordero.png');
  background-color: #cccccc;
  min-height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 25px;
  .textoImg {
    max-width: 600px;
    color: #043056;
  }
}

.modal-pesquisa-cliente {
  .modal-content {
    background: transparent;
    border: 0;
    user-select: none;
  }
  .modal-header {
    z-index: 1;
    border: 0;
  }
  .botao-fechar {
    background: transparent;
    border: 0;
  }
  .botao-fechar:focus {
    outline: 0;
  }
  .img-botao-fechar {
    z-index: 5;
    position: sticky;
    margin-top: 6px;
    margin-bottom: -90px;
    float: right;
    margin-right: 10px;
    cursor: pointer;
  }
  .modal-body {
    padding: 0;
    margin-top: -30px;
    .width-100 {
      width: 100%;
      margin-left: -8px;
    }
    .checkbox-pesquisa {
      text-align: right;
      margin-top: -110px;
      margin-right: 20px;
      .label-nao-mostrar-novamente {
        font-size: 16px;
        color: white;
        cursor: pointer;
        margin-top: -3px;
      }
      .checkbox-nao-mostrar-novamente {
        cursor: pointer;
        .custom-control-label::before {
          cursor: pointer;
        }
        .custom-control-label::after {
          cursor: pointer;
        }
      }
    }
    .link-pesquisa {
      position: absolute;
      left: 57px;
      top: 340px;
      background: transparent;
      border: none;
      width: 333px;
      height: 61px;
      padding: 0;
      border-radius: 8px;
      outline: none;
    }
    @media (max-width: 800px) {
      .link-pesquisa {
        left: 0;
        top: 40px;
        width: calc(100% - 10px);
        height: calc(100% - 47px);
      }
    }
    @media (max-width: 425px) {
      .img-botao-fechar {
        width: 40px;
        margin-top: 5px;
        margin-right: -10px;
      }
      .checkbox-pesquisa {
        margin-top: -70px;
        .label-nao-mostrar-novamente {
          font-size: 14px;
        }
      }
    }
    @media (max-width: 320px) {
      .checkbox-pesquisa {
        margin-top: -55px;
        .label-nao-mostrar-novamente {
          font-size: 12px;
        }
      }
    }
  }
}

.card-pesquisa-clientes {
  border: 0;
  background: transparent;

  .margin-top-1 {
    margin-top: 1px;
  }

  .margin-bottom-15 {
    margin-bottom: 15px;
  }
  .txt-center {
    text-align: center;
  }
  .align-btn-link {
    text-align: center;
    padding: 0.175rem 0.75rem;
    margin: 5px;
    .btn {
      padding: 0.115rem 1.95rem;
      white-space: nowrap;
    }
    .btn:hover {
      color: #1a284d;
      background: #0088ff;
    }
  }
  .no-padding-right {
    padding-right: 0;
    border-radius: 5px 0 0 5px;
  }
  .no-padding-left {
    border-radius: 0 5px 5px 0;
  }
  .back-color-blue {
    background: #1a284d;
  }
  .text-card-pesquisa {
    color: white;
    font-size: 0.85rem;
    font-weight: 600;
    margin-top: 5px;
  }
  .padding-12 {
    padding-right: 12px;
    padding-left: 12px;
  }

  .min-height {
    min-height: 114px;
  }

  .width-100 {
    max-width: 65px;
    max-height: 85px;
    width: 100%;
  }

  .adjust-height {
    min-height: 30px;
    margin-top: 15px;
  }
}

.chart-card {
  box-shadow: 0px 0px 0px 0px !important;
  .chartAvencer {
    position: relative;
    margin-top: -40px;
  }

  .labelChartAvencer,
  .labelChartVOP {
    position: relative;
    z-index: 2;
  }

  .labelChartSpread {
    margin-top: -5px;
    padding: 0 5px;
  }

  .chartConcentracaoSacado {
    position: relative;
    &.legend-position-top {
      margin-top: -55px;
    }
    &.legend-position-bot {
      margin-top: -115px;
    }
  }

  .labelChartConcentracaoSacado {
    position: relative;
    z-index: 2;
  }

  .linkRelatorios {
    text-decoration: underline;
  }

  .chartVencidos {
    position: relative;
    z-index: 1;
    margin-top: -40px;
  }

  .labelChartVencidos,
  .labelChartVOP {
    position: relative;
    z-index: 2;
  }

  .alvo-chart-vop {
    width: 26px;
    margin: -10px 5px 0 0;
  }

  .chartVOP {
    position: relative;
    z-index: 1;
    margin-top: -40px;
  }

  .labelChartLiquidez {
    position: relative;
    z-index: 2;
    padding-bottom: 0;
  }

  .labelChartLimiteCarteira {
    position: relative;
    z-index: 2;
    padding-bottom: 0;
  }

  .chartLimiteCarteira {
    position: relative;
    z-index: 1;
    margin-top: -40px;
  }

  .chartLimiteCarteiraCompacto {
    margin-top: -10px;
  }

  .chart-pie-hole-margin-value-compacto {
    @media (max-width: 1300px) {
      margin-top: 10px;
    }
  }

  .ajuste-heigth-limites {
    padding-bottom: 0px !important;
    min-height: 427.5px !important;
    height: auto !important;
  }

  .ajuste-heigth-limites-compacto {
    min-height: 550px !important;
    height: 550px !important;
    @media (max-width: 900px) {
      min-height: auto !important;
      height: auto !important;
    }
  }

  .gridLimitesCarteira {
    .grid-item-limites-carteira {
      .semLimiteProduto {
        color: #a7a7a7;
        font-size: 40px;
        text-align: center;
        font-weight: 600;
        margin-top: 100px;
        &.compacto {
          margin-top: 50px;
        }
      }
    }
    .footerLimite {
      margin-top: -30px;
      position: relative;
      z-index: 2;
      height: 26px;
      .labelLimite {
        font-size: 15px;
        line-height: 1.7;
        float: left;
        margin-left: 12%;
        .info-icon-limite {
          color: #0085ff;
          font-size: 20px;
          margin-top: -5px;
          margin-right: 3px;
        }
      }
      .labelLimiteValor {
        font-size: 16px;
        font-weight: 600;
        float: right;
        margin-right: 15%;
      }
    }

    .info-limite-grupo {
      margin-top: 8px;
      background: #d9e0f2;
      border-radius: 4px;
      padding: 8px 0 7px 20px;
      width: 100%;
      margin-left: 20px;
      margin-right: 20px;
      .info-icon-limite {
        color: #0085ff;
        font-size: 20px;
        margin-top: -5px;
        margin-right: 3px;
      }
    }

    .chart-pie-hole-margin-value {
      position: relative;
      top: 22%;
      z-index: 2;
      height: 5px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      color: black;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .margin-value {
        width: 100%;
        span {
          font-size: 1.3em;
          font-weight: 600;
          line-height: 1;
        }
        svg {
          font-size: 2.5em;
          font-weight: 600;
          line-height: 1;
        }
      }
      .margin-label {
        width: 100%;
        font-size: 1.1em;
        color: #747474;
        margin-left: -2px;
      }
    }
  }

  .limite-carteira-label {
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
    background-color: #eff1f6;
    border-radius: 8px;
    width: 80%;
    height: 28px;
    margin-left: auto;
    margin-right: auto;
    line-height: 2;
    position: relative;
    z-index: 2;
    padding-bottom: 0;
  }

  .limite-carteira-label-compacto {
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
    background-color: #eff1f6;
    border-radius: 8px;
    width: 93%;
    height: 28px;
    margin-left: auto;
    margin-right: auto;
    line-height: 2;
    position: relative;
    z-index: 2;
    padding-bottom: 0;
  }

  .labelLimiteCompacto {
    text-align: left !important;
    position: relative;
    z-index: 2;
    font-size: 13px;
    padding: 5px 0;
    white-space: nowrap;
    .icone-grupo {
      margin-left: 5px;
    }
    .info-icon-limite {
      color: #0085ff;
      font-size: 18px;
      margin-top: -5px;
    }
  }

  .margin-value-compacto {
    position: relative;
    font-size: 13px;
    padding: 5px 0;
    white-space: nowrap;
    z-index: 2;
  }

  .chartLiquidez {
    position: relative;
    z-index: 1;
    margin-top: -45px;
  }

  .select-periodo-chart-dasboard {
    height: 34px;
    .MuiOutlinedInput-notchedOutline {
      border-color: #ccc !important;
      border-width: 2px;
    }
    .icone-select {
      margin-right: 10px;
      margin-top: -2px;
    }
  }

  .ajuste-spin-chart {
    .spin-rotation {
      top: 49% !important;
      left: 47% !important;
    }
  }

  &.card-ultimos-borderos {
    min-height: 550px;
    height: 550px;
    .ajuste-responsivo-card-content {
      width: 100%;
      overflow: auto;
      min-height: 265px !important;
    }
    .ajuste-responsivo-box {
      width: 550px;
      white-space: nowrap;
    }
    .ajuste-tamanho {
      padding-bottom: 0px;
      margin-bottom: -10px;
      min-height: 235px;
      padding-top: 40px;
    }
    .ajuste-spin-chart {
      min-height: 400px !important;
      height: 400px !important;
    }
  }
}

.dashboard-charts {
  padding-bottom: 60px;
  flex: 1;
  margin-bottom: 10px;
}

.card-plataforma {
  box-shadow: 0px 8px 16px 0px rgba(26, 40, 77, 0.15) !important;
  border: 1px solid #e9ebf0 !important;
}

#wootric-modal.show {
  position: fixed;
  display: flex;
  justify-content: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: background-color 200ms linear !important;
  -moz-transition: background-color 200ms linear !important;
  -o-transition: background-color 200ms linear !important;
  -ms-transition: background-color 200ms linear !important;
  transition: background-color 200ms linear !important;
}

#wootric-modal.wootric-mobile-modal.show {
  flex-direction: column !important;
}
#wootric-modal:not(.wootric-mobile-modal).show {
  align-items: center;
}

#wootric-modal .wootric-beacon-parent {
  width: auto !important;
  padding: 25px !important;
}

.status-mesa {
  background: transparent !important;
  width: 101.6%;
  margin-left: -0.75%;
  @media (max-width: 1400px) {
    width: 101.8% !important;
    margin-left: -0.8% !important;
  }
  .ajuste-card-mesa {
    padding: 0 !important;
    width: 100%;
    overflow: auto;
  }
  .box-principal-status-mesa {
    min-height: 350px;
    min-width: 825px;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    .box-card-status-mesa {
      width: 23.7%;
      margin-bottom: 23px;
      padding: 5px 12px;
      .box-status-card {
        padding: 15px;
        background: white;
        width: 100%;
        height: 140px;
      }
      .cor-status-card {
        border-top: 6px solid;
        border-radius: 40px;
        margin-top: 5px;
      }
      .descricao-status {
        color: #08234d !important;
        .quantidade-status {
          font-size: 30px;
          font-weight: 600;
          margin-left: 2px;
          margin-right: 12px;
        }
        .nome-status {
          text-transform: capitalize;
          font-size: 15px;
          overflow: auto;
          white-space: nowrap;
        }
        .valor-status {
          font-size: 16px;
          font-weight: 600;
        }
        hr {
          margin-top: 0;
          background: #eeeeee;
        }
      }
    }
  }
}

.chart-pie-hole-selected-value {
  position: relative;
  z-index: 2;
  margin-left: auto !important;
  margin-right: auto !important;
  width: fit-content;
  font-weight: 700 !important;
  line-height: 120% !important;
  color: #1a284d;
  height: 45px;
  &.legend-position-bot {
    top: 130px;
  }
  &.legend-position-top {
    top: 190px;
  }
  @media (min-width: 450px) {
    font-size: 2.5rem !important;
    .percentual {
      font-size: 1.5rem !important;
    }
  }
  @media (max-width: 450px) {
    font-size: 1.2rem !important;
    .percentual {
      font-size: 0.8rem !important;
    }
  }
}

.charts-borderos-limites {
  .MuiCardContent-root {
    min-height: 300px;
  }
}

.charts-vencidos-avencer {
  .MuiCardContent-root {
    height: 500px;
  }
}

.charts-liquidez-concentracao {
  .MuiCardContent-root {
    height: 500px;
  }
}

.charts-vop-limites-carteira {
  .ajuste-spin-chart {
    min-height: 525px;
    height: 525px;
    @media (min-width: 901px) and (max-width: 1200px) {
      height: 785px;
      padding-bottom: 15px !important;
    }
    @media (max-width: 900px) {
      height: auto;
      padding-bottom: 15px !important;
    }
  }
}

.charts-ultimos-borderos-limites-carteira {
  .ajuste-spin-chart {
    @media (min-width: 901px) and (max-width: 1200px) {
      height: 785px;
      padding-bottom: 15px !important;
    }
    @media (max-width: 900px) {
      height: auto;
      padding-bottom: 15px !important;
    }
  }
}

.charts-vop-limites-carteira,
.charts-ultimos-borderos-limites-carteira {
  @media (min-width: 1200px) {
    .gridLimitesCarteira {
      align-content: center;
      margin-top: 0 !important;
      .limite-carteira-label {
        margin-bottom: 15px;
      }
      .limite-carteira-label-compacto {
        margin-bottom: 15px;
      }
    }
  }
}

.esconder-coluna-grid {
  display: none !important;
}

.row-ultimos-borderos {
  margin-top: 10px;

  .row-borderos {
    padding: 20px 0;
    background-color: #f5f5f5;
  }

  .cor-borda-texto-success {
    border-left: 8px solid #6a9b34;
    border-radius: 4px;
  }
  .cor-borda-texto-warning {
    border-left: 8px solid #d88c31;
    border-radius: 4px;
  }
  .cor-borda-texto-danger {
    border-left: 8px solid #cb444b;
    border-radius: 4px;
  }
  .cor-borda-texto-info {
    border-left: 8px solid #0085ff;
    border-radius: 4px;
  }
}

.data-grid-column-group {
  .MuiDataGrid-columnHeaders {
    min-height: 65px !important;
    max-height: 65px !important;
    align-items: flex-end !important;
    // div[class$='MuiDataGrid-columnHeaderRow'][aria-rowindex='1'] {
    div[role='row'][aria-rowindex='1'] {
      height: auto !important;
      align-items: flex-end !important;
      .MuiDataGrid-columnHeader {
        text-align: center !important;
        justify-content: center !important;
        align-items: flex-end !important;
        height: 28px !important;
        line-height: 1 !important;
        .MuiDataGrid-columnHeaderTitleContainer {
          text-align: center !important;
          justify-content: center !important;
          border: 0 !important;
          align-items: flex-end !important;
        }
        .MuiDataGrid-columnSeparator {
          min-height: 28px !important;
          height: 28px !important;
        }
      }
    }
    div[role='row'][aria-rowindex='2'] {
      height: auto !important;
      .MuiDataGrid-columnHeader {
        height: 36px !important;
        .MuiDataGrid-columnSeparator {
          min-height: 36px !important;
          height: 36px !important;
        }
      }
    }
  }
  p.MuiTablePagination-selectLabel,
  p.MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
  }
}

.data-grid-header-centralizado {
  .MuiDataGrid-columnHeaderTitleContainer {
    text-align: center !important;
    justify-content: center !important;
  }
}

.data-grid-header-centralizado-esquerda {
  .MuiDataGrid-columnHeaderTitleContainer {
    text-align: left !important;
    justify-content: left !important;
  }
}

.data-grid-header-centralizado-direita {
  .MuiDataGrid-columnHeaderTitleContainer {
    text-align: right !important;
    justify-content: right !important;
  }
}

.data-grid-header-centralizado,
.data-grid-header-centralizado-esquerda,
.data-grid-header-centralizado-direita {
  .MuiDataGrid-columnHeaderTitleContainer {
    align-items: center !important;
  }
  .MuiDataGrid-iconButtonContainer {
    width: 28px !important;
  }
}

.data-grid-header-flow-reverse {
  .MuiDataGrid-columnHeaderTitleContainer {
    flex-direction: row-reverse;
  }
}

.data-grid-separator-header-hidden {
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }
}

.data-grid-light-blue {
  background-color: #ffffff;
}

.data-grid-blue {
  background-color: #d9e0f2;
  &.MuiDataGrid-withBorderColor {
    border-width: 2px !important;
    border-color: #fff !important;
  }
}

.data-grid-light-gray {
  background-color: #f5f5f5;
  &.MuiDataGrid-withBorderColor {
    border-width: 2px !important;
    border-color: #fff !important;
  }
}

.data-grid-borderless {
  border-color: #fff !important;
}

.font-bold-header {
  font-weight: bold !important;
  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
  }
}

.azul-one7-cor {
  color: #1a284d !important;
}

.azul-claro-background {
  background-color: #ededf7 !important;
}

.alertasProduto {
  padding-top: 12px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-left: 16px;
  border-radius: 4px;
  width: 100%;
}

.texto-cor-cinza-escuro {
  color: #454545 !important;
}

.tableExibirProdutos {
  border-radius: 4px;
  border: 1px solid #ededf7 !important;
  .produto-col {
    border-right: 1px solid #e6e7f0 !important;
  }
}

.ajuste-spin-data-grid {
  .spin-rotation {
    top: 49% !important;
    left: 47% !important;
  }
}

.data-grid-clientes-dashboard {
  margin-bottom: 20px !important;
  padding-bottom: 10px !important;
  border: 0 !important;

  .statusGrid {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    .iconBtnStatus {
      padding: 0 2px;
    }
  }

  .iconValorConjunto {
    width: 12px; 
    height: 12px; 
    margin-left: 5px;
    margin-top: -5px;
  }

  .iconCorrenteFechadaGrid {
    width: 18px;
    height: 18px;
  }

  .iconCorrenteAbertaGrid {
    width: 22px;
    height: 22px;
  }

  .campoGrupoEconomicoGrid {
    display: flex;
    align-items: center;
    max-width: auto;
    overflow: hidden;
  }

  .dadosEmpresaGrid {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;

    .camposEmpresaGrid {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .btnAcoesGrid {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconBtnAcoes {
      color: #1976d2;
    }
  }

  .custom-toolbar-container {
    width: 100%;
    width: 1224;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 15px !important;
    border-bottom: 1px solid #809ff04d;
  }

  .btnLimparConteudo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .custom-toolbar-grid {
    padding-left: 24px;
    padding-right: 24px;
  }

  .texto-input {
    margin-bottom: 8px;
    color: #1b1b20 !important;
  }

  .inputGridCliente {
    background-color: #f2f2fc;
    border-radius: 4px;
    width: 376;
    padding-top: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    padding-left: 4px;

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiOutlinedInput-root {
      fieldset {
        border: none;
      }
    }

    input::placeholder {
      color: #1b1b20 !important;
    }
  }

  .exportarGridClientes {
    width: auto !important;
    border-radius: 8px;
    margin-top: 20px;
    padding: 10px;
    width: 140px;
    min-width: 110px !important;
  }

  .custom-radio-group {
    .MuiRadio-root.Mui-checked {
      color: #1a284d !important;
    }
  }

  .btn-qtd-produtos {
    background-color: #ededf7;
    width: 100%;
    height: 30px;
    border-radius: 4px;
    font-weight: bold;
    color: #45464f;
    border: 1px solid #d8d8e1;
    &:hover {
      background-color: #dde8ff;
    }
  }

  .MuiDataGrid-columnHeaders {
    padding-top: 80px !important;
    margin-bottom: 15px !important;
    background-color: #ffffff;
    margin: 0.5px;

    box-shadow: 0px 1px 2.5px 1.5px #809ff04d, 0px 2px 5px 3px #809ff026;
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    padding: 0 5px !important;
    border: 0 !important;
    margin-bottom: 10px;
  }

  .MuiDataGrid-row:hover {
    background-color: transparent !important;
  }

  .borda-cor-siga {
    width: 5px !important;
    min-width: 5px !important;
    padding: 0 !important;
    max-width: 5px !important;
    margin-left: 0px;
  }
  .borda-cor-siga-head,
  .header-cor-siga {
    padding: 0 !important;
    max-width: 5px !important;
    width: 5px !important;
    min-width: 5px !important;
  }
  .MuiDataGrid-toolbarContainer {
    padding: 5px 4px;
    button {
      margin-left: 10px;
    }
  }
}

.icon-grid-clientes-qtd-paginas {
  position: absolute;
  z-index: 2;
  padding: 2px;
  background: #1a284d;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  width: 16px;
  height: 15px;
  font-size: 10px;
  line-height: 1.1;
}

.badge-padrao {
  padding: 2.3px 2px;
  border: 1px solid;
  border-radius: 20px;
  text-align: center;
  min-width: 22px;
  height: 18px;
  min-height: 18px;
  font-size: 11px;
  line-height: 1.1;
  display: inline-block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badge-padrao-2 {
  padding: 3px 5px;
  border: 1px solid;
  border-radius: 15px;
  text-align: center;
  min-width: 25px;
  height: 22px;
  min-height: 22px;
  font-size: 12px;
  line-height: 1.1;
  display: inline-block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badge-padrao-proposta-cliente {
  padding: 3px 15px;
  border: 1px solid;
  border-radius: 15px;
  text-align: center;
  min-width: 25px;
  height: 22px;
  min-height: 22px;
  font-size: 12px;
  line-height: 1.1;
  display: inline-block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badge-padrao-proposta-cliente-checkpoint {
  padding: 3px 15px;
  border: 1px solid;
  border-radius: 15px;
  text-align: center;
  width: 150px;
  height: 22px;
  min-height: 22px;
  font-size: 12px;
  line-height: 1.1;
  display: inline-block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.teste {
  justify-content: space-around;
}

.box-pre {
  white-space: pre;
}

.badge-padrao-3 {
  padding: 4px;
  border: 1px solid;
  border-radius: 8px;
  text-align: center;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  font-size: 0.8rem;
  line-height: 1.1;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badge-cor-padrao {
  border-color: #1a284d;
  background-color: #fff;
  color: #1a284d;
}

.badge-cor-padrao-cinza {
  border-color: #b8b9bd;
  background-color: #fff;
  color: #1a284d;
}

.Mui-selected {
  .badge-selecionavel {
    color: #fff !important;
    background-color: #1a284d !important;
  }
}

.data-grid-operacoes-vop {
  margin-bottom: 10px !important;
  .MuiDataGrid-columnHeaders {
    border-color: #fff !important;
  }
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    padding: 10px 15px !important;
    color: #454545;
  }
}

.grid-last-line-total {
  .MuiDataGrid-row--lastVisible {
    border-top: 2px solid #6782cc !important;
  }
}

#btn-grupo-economico {
  #chain_white {
    display: none;
  }
  &:hover {
    #chain {
      display: none;
    }
    #chain_white {
      display: unset;
    }
  }
}

.chartVOP {
  g {
    text[text-anchor='middle'] {
      cursor: pointer !important;
    }
  }
}

.circulo-esteira {
  min-width: 34px;
  width: 34px;
  min-height: 34px;
  height: 34px;
  border-radius: 50%;
}

.box-parecer {
  .sub-titulo {
    background: #eeeeee;
  }
  .time-line {
    .border-line-right {
      border-right: 2px solid #e0e0e0;
      min-height: 150px;
      width: 25px;
    }
    .border-line-left {
      border-left: 1px solid #e0e0e0;
      min-height: 150px;
      width: 25px;
    }
    .time-card {
      display: flex;
    }
    .timeline-badge-box {
      width: 50px;
      height: 50px;
      margin-left: -25px;
      text-align: center;
      margin-top: 50px;
      background-color: #ffffff;

      .timeline-badge-color {
        width: 50px;
        height: 50px;
        border-radius: 50% 50% 50% 50%;
        text-align: center;
        font-size: 1.4em;
        line-height: 50px;
        color: #fff;
        filter: saturate(135%);
      }
    }
    .timeline-badge {
      width: 50px;
      height: 50px;
      margin-left: -25px;
      border-radius: 50% 50% 50% 50%;
      text-align: center;
      font-size: 1.4em;
      line-height: 50px;
      color: #fff;
      margin-top: 50px;
      filter: saturate(135%);
    }
    .timeline-card {
      float: left;
      position: relative;
      width: 90%;
      padding: 20px;
      padding-bottom: 0;
      border: 1px solid #e0e0e0;
      border-radius: 2px;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      left: 18px;
      margin-top: 35px;
      @media (max-width: 425px) {
        width: 85%;
      }
      @media (max-width: 375px) {
        width: 80%;
      }
      @media (max-width: 320px) {
        width: 75%;
      }
      &:before {
        content: ' ';
        display: inline-block;
        position: absolute;
        top: 26px;
        right: -15px;
        border-top: 15px solid transparent;
        border-right: 0 solid #e0e0e0;
        border-bottom: 15px solid transparent;
        border-left: 15px solid #e0e0e0;
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
      }
      .timeline-title {
        font-size: 16px;
      }
      .timeline-inclusao {
        color: #9e9e9e;
        font-size: 14px;
        margin-top: 2px;
      }
      .limitar-tamanho-parecer {
        overflow: hidden !important;
        max-height: 100px !important;
        transition: max-height 1s ease-in-out !important;
      }
      .box-pareceres {
        transition: max-height 1s ease-in-out;
        max-height: 350px;
        border: 1px solid #e0e0e0;
        padding: 15px;
        margin-top: 5px;
        border-radius: 5px;
        overflow: auto;
        table {
          display: contents;
        }
      }
    }
  }
}

.box-dashboard {
  .sub-titulo {
    background: #e6e7f0;
    padding: 8px;
  }
  .sub-titulo-azul {
    background: #c6ebf9;
    padding: 8px;
  }
  .informativo-sub-titulo {
    color: #45464f;
    font-size: 12px;
    margin-top: 2px;
    font-style: italic;
  }
  .time-line {
    .border-line-right {
      border-right: 2px solid #e0e0e0;
      min-height: 150px;
      width: 25px;
    }
  }
}

.autocomplete-multiple-small {
  height: 20px;
  .MuiTextField-root {
    background-color: #edf0f4;
    z-index: 999;
    position: relative;
    inset: 0 auto auto 0;
    margin: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #ccc !important;
    border-width: 2px;
    height: auto !important;
  }
}

.select-dashBoard {
  font-size: 15px;
}

.historico-container {
  margin-top: 1rem;

  .historico-title {
    color: #1a284d;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .historico-box {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: auto;
    padding-right: 16px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dddddd;
      border-radius: 4px;

      &:hover {
        background-color: #dddddd;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: #ffffff;
    }
  }

  .checkPoint {
    margin-bottom: 1rem;
    padding-right: 15px;

    .checkPoint-header {
      .chip {
        background-color: #5ad4ff;
        color: #1a284d;
        font-weight: bold;
      }

      .corCinzaEscuro {
        color: #45464f !important;
      }

      .corCinzaClaro {
        color: #74767e !important;
      }
    }

    .checkPoint-description {
      margin-top: 1rem;
      color: #45464f;
      text-align: justify;
    }

    .checkPoint-divider {
      margin-top: 1rem;
    }
  }
}

.formCheckpoint {
  .invalid-feedback {
    font-size: 12px !important;
    margin-left: 2px !important;
    margin-top: 8px !important;
  }
}
